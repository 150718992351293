import PageHeader from "../../components/PageHeader";

const Imprint = () => {
  return (
    <>
      <section className="container">
        <PageHeader title="Imprint" />
        <div className="container contentTitle" >
          <p>Brands: Balaio & Forró with Matheus</p>
          <p>Legal Representative: Matheus Antunes</p>
          <p>Contact: forrowithmatheus@gmail.com</p>
        </div>
      </section>
    </>
  );
};

export default Imprint;
