import Header from "./components/Header";
import Footer from "./components/Footer";

import { Routes, Route, useLocation, Navigate } from "react-router-dom";

import Landing from "./pages/landing/Landing";
import FindMe from "./pages/findme/FindMe";
import Online from "./pages/online/Online";
import Contact from "./pages/contact/Contact";
import Imprint from "./pages/imprint/Imprint";
import PageNotFound from "./pages/404/PageNotFound";

function App() {
  const personalDetails = {
    name: "Matheus",
    location: "Berlin - Germany",
    email: "info@forrowithmatheus.com",
    brand:
      "Connecting people through Forró.",
  };

  const location = useLocation();

  return (
    <>
      <Header />
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Landing name={personalDetails.name} tagline={personalDetails.tagline} />} />
        <Route path="/findme" element={<FindMe />} />
        <Route path="/online" element={<Online />} />
        <Route path="/imprint" element={<Imprint />} />

        <Route
          path="/contact"
          element={
            <Contact
              name={personalDetails.name}
              location={personalDetails.location}
              email={personalDetails.email}
            />
          }
        />
        <Route path="/page-not-found" element={<PageNotFound />} />
        <Route path="*" element={<Navigate to="/page-not-found" />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
