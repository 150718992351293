import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import { FiArrowUpRight } from "react-icons/fi";

const BerlinInfo = ({ name }) => {
  const staggerVariants = {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const paragraphVariants = {
    initial: { y: 20, opacity: 0 },
    animate: { y: 0, opacity: 1 },
  };

  return (
    <div className="container" style={{"padding":"0px"}}>
      <div className="topicContainer col-12" style={{"marginBottom":"15px", "backgroundColor":"var(--bg2-color)"}}>
        <motion.div className="contentContainer" variants={staggerVariants}>
          <motion.h4 variants={paragraphVariants} style={{"marginBottom":"0px", "textAlign":"center"}}>
            We are back to business!
          </motion.h4>
          <motion.h5 variants={paragraphVariants} style={{"marginBottom":"0px", "textAlign":"center"}}>
            Wednesdays 19:15 - 20:45
          </motion.h5>
          <br/>
          <motion.p variants={paragraphVariants} style={{"marginBottom":"0px", "textAlign":"center", "color":"grey"}}>
            New address in Wedding: <br/>Adolfstr. 12, 13347 Berlin
          </motion.p>
          <motion.div style={{"display":"flex", "justifyContent":"center"}}>
            <motion.button className="btn" whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.99 }}>
              <a target="_blank" rel="noreferrer" href="https://tickets.balaioberlin.com/oct/">
                Book a Class
              </a>
              <div>
                <FiArrowUpRight whileHover={{ scale: 4 }} className="arrow-icon" />
              </div>
            </motion.button>
          </motion.div>
        </motion.div>
      </div>
      <div style={{ "display":"none" }} className="row">
        <div className="topicContainer col-12 col-lg-6" style={{"marginBottom":"15px", "backgroundColor":"var(--bg2-color)"}}>
          <motion.div className="contentContainer" variants={staggerVariants}>
            <motion.h5 variants={paragraphVariants} style={{"marginBottom":"0px"}}>Timetable</motion.h5>
            <motion.p className="contentDescription">
              <p>Our classes take place on 4 <b style={{"color":"white"}}>Thursdays</b> per month.</p>
              <i>PS.: there will be no class on November 23.</i>
            </motion.p>
            <br />
            <motion.h3 variants={paragraphVariants}>Beginners - 6:45pm</motion.h3>
            <motion.p className="contentDescription">From 0 to having fun with basic moves</motion.p>
            <br />
            <motion.h3 variants={paragraphVariants}>Intermediate - 7:45pm</motion.h3>
            <motion.p className="contentDescription">Improving repertoire and techniques</motion.p>
            <br />
            <motion.h3 variants={paragraphVariants}>Advanced - 8:45pm</motion.h3>
            <motion.p className="contentDescription">Advanced concepts and movements</motion.p>
            {/* <NavLink to="https://maps.app.goo.gl/JDXx9oYdzDn7Pt816" target="_blank">
              <motion.button className="btn" whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.99 }}>
                <p>Find us on the map</p>
              </motion.button>
            </NavLink> */}
          </motion.div>
        </div>
        <div className="col-12 col-lg-6">
          <motion.div className="contentContainer" variants={staggerVariants}>
            <motion.h5 variants={paragraphVariants} style={{"marginBottom":"0px"}}>Princing</motion.h5>
            <motion.p className="contentDescription">Be a member (discount until November) or pay-per-day (drop-in)</motion.p>
            <br />
            <motion.h3 variants={paragraphVariants}>Drop-in</motion.h3>
            <motion.p className="contentDescription">
              1 Class: <span style={{"color":"white"}}>15€</span> | 
              2 Classes: <span style={{"color":"white"}}>25€</span>
            </motion.p>
            <br />
            <motion.h3 variants={paragraphVariants}>Membership</motion.h3>
            <motion.p className="contentDescription">
              <p style={{"margin-bottom": "5px"}}>1 class per Thursday &gt; 44€/month = <span style={{"color":"white"}}>11€/class</span><br/></p>
              <p>2 Classes per Thursday &gt; 66€/month = <span style={{"color":"white"}}>8,25€/class</span></p>
            </motion.p>
            <br />
            <motion.h3 variants={paragraphVariants}>Urban Sports (USC)</motion.h3>
            <motion.p className="contentDescription">
              <p style={{"color":"white"}}>Come get a trial class!</p>
              * For long-term participation, please get more info at our school.
            </motion.p>
            {/* <NavLink to="https://forms.gle/Gf1oobtYZbarUXAy7" target="_blank">
              <motion.button className="btn" whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.99 }}>
                <p>Become a member</p>
              </motion.button>
            </NavLink> */}
          </motion.div>
        </div>
      </div>
      {/* style={styles.actionButtons} */}
      <div style={{ "display":"none" }}> 
       <NavLink to="https://maps.app.goo.gl/rwV4HydZ9wA5T5XT7" target="_blank">
          <motion.button className="btn" whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.99 }}>
            <p>Find us on the map</p>
          </motion.button>
        </NavLink>
        <NavLink to="https://forms.gle/Gf1oobtYZbarUXAy7" target="_blank">
          <motion.button className="btn" whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.99 }}>
            <p>Become a member</p>
          </motion.button>
        </NavLink>
      </div>
    </div>
  );
};

export default BerlinInfo;
